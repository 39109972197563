import Validator from "validator";
import isEmpty from "lodash/isEmpty";
import { isNull } from "lodash";

// Validate user login on submit
export function validateLogin(data) {
    let errors = {};
	if (Validator.isEmpty(data.email)) {
		errors.email = "Email is required";
	} else if(!Validator.isEmail(data.email)) {
        errors.email = "Please enter a valid Email"
    }
    if(!data.otp_login) {
        if(Validator.isEmpty(data.password)) {
            errors.password = "Password is required"
        } else if(data.password.length < 8) {
            errors.password = "Password length should be minimum 8"
        }
    }
    if(data.otp_login && data.otp_send) {
        if(Validator.isEmpty(data.password)) {
            errors.password = "OTP is required"
        } else if(data.password.length < 6) {
            errors.password = "OTP length should be minimum 6"
        }
    }

	return {
		errors,
		isValid: isEmpty(errors),
	};
}

// Validate user login on submit
export function validateSignup(data) {
    let errors = {};
	if (Validator.isEmpty(data.email)) {
		errors.email = "Email is required";
	} else if(!Validator.isEmail(data.email)) {
        errors.email = "Please enter a valid Email";
    }
    if (Validator.isEmpty(data.first_name)) {
		errors.first_name = "First name is required";
    }
    if (Validator.isEmpty(data.last_name)) {
		errors.last_name = "Last name is required";
    }
	// if (Validator.isEmpty(data.phone_number)) {
	// 	errors.phone_number = "Mobile number is required";
	// } else if(!Validator.isMobilePhone(data.phone_number)) {
    //     errors.phone_number = "Please enter a valid Mobile number";
    // }
    if(Validator.isEmpty(data.password)) {
        errors.password = "Password is required";
    } else if(data.password.length < 8) {
        errors.password = "Password length should be minimum 8";
    }
    if(Validator.isEmpty(data.confirm_password)) {
        errors.confirm_password = "Confirm Password is required";
    } else if(data.confirm_password.length < 8) {
        errors.confirm_password = "Confirm Password length should be minimum 8";
    }
    if(!Validator.isEmpty(data.password) && !Validator.isEmpty(data.confirm_password)) {
        if(data.password !== data.confirm_password) {
            errors.confirm_password = "Password and Confirm passowrd must be same";
        }
    }

	return {
		errors,
		isValid: isEmpty(errors),
	};
}

// Validate forgot password on submit
export function validateForgotPassword(data) {
    let errors = {};
	if (Validator.isEmpty(data.email)) {
		errors.email = "Email is required";
	} else if(!Validator.isEmail(data.email)) {
        errors.email = "Please enter a valid Email"
    }
    // if(data.otp_send) {
    //     if(Validator.isEmpty(data.otp)) {
    //         errors.otp = "OTP is required"
    //     } else if(data.otp.length < 7) {
    //         errors.otp = "OTP length should be minimum 5"
    //     }
    // }
	return {
		errors,
		isValid: isEmpty(errors),
	};
}

// Validate forgot password on submit
export function validateContactData(data) {
    let errors = {};
	if (Validator.isEmpty(data.email)) {
		errors.email = "Email is required";
	} else if(!Validator.isEmail(data.email)) {
        errors.email = "Please enter a valid Email"
    }
    if (Validator.isEmpty(data.first_name)) {
		errors.first_name = "First name is required";
	}
    if (Validator.isEmpty(data.last_name)) {
		errors.last_name = "Last name is required";
	}
    // if (Validator.isEmpty(data.phone_number)) {
	// 	errors.phone_number = "Phone number is required";
	// }
    if (Validator.isEmpty(data.subject)) {
		errors.subject = "Subject is required";
	}
    if (Validator.isEmpty(data.comments)) {
		errors.comments = "Comments is required";
	}
	return {
		errors,
		isValid: isEmpty(errors),
	};
}

// Validate change password on submit
export function validateChangePassword(data) {
    let errors = {};
	if (typeof data.password !== "undefined" && Validator.isEmpty(data.password)) {
		errors.password = "Password is required";
	} else if (!Validator.isEmpty(data.password) && data.password.length < 6) {
		errors.password = "Password should be mininum 5 in length";
	}

	if (typeof data.confirm_password !== "undefined" && Validator.isEmpty(data.confirm_password)) {
		errors.confirm_password = "Confirm Password is required";
	}
	if (typeof data.confirm_password !== "undefined" && data.password !== data.confirm_password) {
		errors.confirm_password = "Password and confirm password should match";
	}

	return {
		errors,
		isValid: isEmpty(errors),
	};
}

// Validate Save userinfo on submit
export function validSaveData(data, tab) {
    let errors = {};
    if(tab == 1 && (isNull(data.user.first_name) || Validator.isEmpty(data.user.first_name))) {
        errors.first_name = "First name is required";
    }
    if(tab == 1 && (isNull(data.user.last_name) || Validator.isEmpty(data.user.last_name))) {
        errors.last_name = "Last name is required";
    }
    if(tab == 2 && Validator.isEmpty(data.current_password)) {
        errors.current_password = "Current Password is required";
    }
    if(tab == 2 && Validator.isEmpty(data.new_password)) {
        errors.new_password = "New Password is required";
    }
    if(data.change_email && (isNull(data.user.email_id) || Validator.isEmpty(data.user.email_id))) {
        errors.email_id = "Email is required";
    }
    if(data.change_mobile_num && (isNull(data.user.phone_num_country_code) || Validator.isEmpty(data.user.phone_num_country_code))) {
        errors.phone_num_country_code = "Country code is required";
    }
    if(data.change_mobile_num && (isNull(data.user.phone_number) || Validator.isEmpty(data.user.phone_number))) {
        errors.phone_number = "Phone number is required"
    }
    if(data.change_address && (isNull(data.user.address_line1) || Validator.isEmpty(data.user.address_line1))) {
        errors.address_line1 = "Address line 1 is required"
    }
    if(data.change_address && (isNull(data.user.address_line2) || Validator.isEmpty(data.user.address_line2))) {
        errors.address_line2 = "Address line 2 is required"
    }
    if(data.change_address && (isNull(data.user.city) || Validator.isEmpty(data.user.city))) {
        errors.city = "City is required"
    }
    if(data.change_address && (isNull(data.user.state) || Validator.isEmpty(data.user.state))) {
        errors.state = "State is required"
    }
    if(data.change_address && (isNull(data.user.pincode) || Validator.isEmpty(data.user.pincode))) {
        errors.pincode = "Pincode is required"
    }
    return {
		errors,
		isValid: isEmpty(errors),
	};
}

// Validate Save userinfo on submit
export function validateAccoutDetails(data) {
    let errors = {};
    if(isNull(data.user.first_name) || Validator.isEmpty(data.user.first_name)) {
        errors.first_name = "First name is required";
    }
    if(isNull(data.user.last_name) || Validator.isEmpty(data.user.last_name)) {
        errors.last_name = "Last name is required";
    }
    
    return {
		errors,
		isValid: isEmpty(errors),
	};
}