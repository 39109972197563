import Head from 'next/head';
import React from 'react';
import { connect } from 'react-redux';
import { logout, switchMode } from '../src/actions/actions';
import Login from '../src/components/login';
import CONFIG, { LOGO_URL } from '../src/config';
import { withRouter } from 'next/router';
import dynamic from 'next/dynamic';

const AlertMessage = dynamic(import('../src/containers/alert-message'), { ssr: false });

// Login page
class LoginPage extends React.Component {
    render() {
        const { darkTheme } = this.props;
        return (
            <>
                <Head>
                    <title>Login - {CONFIG.APP_NAME}</title>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <meta property="og:url" content={CONFIG.SITE_URL+"/login/"}/>
                    <meta property="og:title" content={"Login - "+CONFIG.APP_NAME}/>
                    <meta property="og:site_name" content={CONFIG.APP_NAME}></meta>
                    <meta property="og:image" content={LOGO_URL}></meta>
                    <meta property="og:description" content={"Login - "+CONFIG.APP_NAME}></meta>
                    <base href="/"/>
                </Head>
                <div className={darkTheme ? "dark_theme" : "light_theme"} style={{ overflowY : 'scroll', minHeight: 'calc(100vh)', maxHeight: 'calc(100vh)'}}>
                    <AlertMessage/>
                    <Login 
                        {...this.props}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.AppReducer.user,
        isAuth: state.AppReducer.isAuth,
        darkTheme: state.AppReducer.darkTheme
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        switchMode: (theme) => dispatch(switchMode(theme))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));