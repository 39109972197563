import React from 'react';

// Loading GIF
function Loader() {
    return (
        <div className="position-absolute d-flex flex-column loader-gif-bg">
            <img src="/images/loading.gif"/>
        </div>
    )
}

export default Loader;