import Link from "next/link";
import CONFIG, { PAGES } from "../config";

const AuthPageLogo = () => {
    return (
        <Link href="/" className="auth-page-logo navbar-brand d-flex align-items-center mt-2">
            <img 
                src={PAGES.LOGIN.LOGO_URL_50}
                width={60}
                height={60}
                className="tenent-logo"
                alt={`${CONFIG.APP_NAME}-logo`}
            />
            <span className="logo">
                <img 
                    src={PAGES.LOGIN.LOGO_TEXT_URL}
                    height="100%"
                    alt={`${CONFIG.APP_NAME}-logo`}
                />
            </span>
        </Link>
    )
}

export default AuthPageLogo;