import React from "react";
import CONFIG from "../config";
import Axios from "axios";
const { useGoogleLogin } = require("@react-oauth/google");

const GoogleSignIn = ({ handleGoogleLogin }) => {
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log(tokenResponse);
            const userInfo = await Axios.get('https://www.googleapis.com/oauth2/v3/userinfo',{ headers: { Authorization: `Bearer ${tokenResponse.access_token}` } } );
            if(userInfo && userInfo.data) {
                handleGoogleLogin(userInfo.data);
            }
        },
        onError: errorResponse => console.log(errorResponse),
    });
    return (
        <span onClick={googleLogin} className="text-center w-100 cursor-pointer">
            <img src="/images/social/google.png" width="25" height="25" alt={CONFIG.APP_NAME+"-google-icon"}/>
            {/* <span className="ms-3 google ff-ember-rg">Connect with Google</span> */}
        </span>
    )
}

export default React.memo(GoogleSignIn);