import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import { getRefreshToken, getSaltKey, loginOrRegister, socialLogin, sendOtp, verifyOtp, login, resendOtp } from '../actions/actions';
import { validateLogin } from '../common/validations';
import { withRouter } from 'next/router';
import Loader from '../common/loader';
import { addAlertMessage } from '../actions/alert-messages';
import { browserName, fullBrowserVersion, osName } from 'react-device-detect';
import { nanoid } from 'nanoid';
import CONFIG, { OTP_RESEND_TIMER, PAGES } from '../config';
import { INVALID_TOKEN, OS_TYPES } from '../common/constant';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { removeAllLocalCache, removeLocalStorage } from '../common/utils';
import md5 from 'md5';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleSignin from '../common/google-signin';
import AppleSignIn from '../common/apple-login';
import AuthPageLogo from '../common/auth-page-flxed-logo';

class LoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            otp: "",
            password: "",
            show_password: false, 
            otp_login: false,
            otp_send: false,
            timer: OTP_RESEND_TIMER,
            resend_otp: false,
            errors: {},
            loader: false
        }
    }
    // Event handler for input changes like usermail and password
    handleChange = (e) => {
        const field = e.target.name;
        const val = e.target.value;
        let errors = this.state.errors;
        this.setState({ [field]: val });
        if (val !== '' && errors) {
            errors[field] = ''
        }
        this.setState({ errors })
    }
    // Validation function for login form
    isValid() {
        const { errors, isValid } = validateLogin(this.state);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }
    // Event handler for login button
    login = (e) => {
        let deviceToken = localStorage.getItem("device_token");
        if(deviceToken == null) {
            deviceToken = uuidv4();
        }
        let data = {
            p_id: process.env.P_ID,
            email_id: this.state.email,
            password_hash: md5(this.state.password),
            os_type_id: OS_TYPES[osName],
            serial_number: "NA",
            model: browserName,
            version: fullBrowserVersion,
            unique_id: nanoid(),
            device_token: deviceToken
        }
        // Login API from Redux actions
        this.props.login(data).then(
            async (res) => {
                if(res.data.success) {
                    this.setState({ loader: false })
                    if(this.props.loginFromPackage === true) {
                        this.props.router.push('order-summary')
                    } else {
                        if(this.props.router.query?.redirect_uri) {
                            this.props.router.push(this.props.router.query.redirect_uri);
                        } else {
                            this.props.router.push('/')
                        }
                    }
                } else {
                    // If invalid access token then get new access token using same function
                    if(res.data.message === INVALID_TOKEN) {
                        await removeAllLocalCache();
                        this.loginOrRegister(e);
                    } else {
                        if(res.data.parameters && res.data.parameters.action == "VERIFY_OTP") {
                            this.setState({ otp_login: true, otp_send: true, password: "",  timer: OTP_RESEND_TIMER }, () => {
                                this.startTimer();
                            });
                        }
                        if(res.data.parameters && res.data.parameters.action == "REDIRECT_TO_SIGNUP") {
                            this.props.router.push('/sign-up')
                        }
                        this.setState({ loader: false })
                    }
                }
            }
        )
    }
    sendOtp = () => {
        let deviceToken = localStorage.getItem("device_token");
        if(deviceToken == null) {
            deviceToken = uuidv4();
        }
        let data = {
            p_id: process.env.P_ID,
            email_id: this.state.email,
            os_type_id: OS_TYPES[osName],
            serial_number: "NA",
            model: browserName,
            version: fullBrowserVersion,
            unique_id: nanoid(),
            device_token: deviceToken
        }
        // Login API from Redux actions
        this.props.sendOtp(data).then(
            async (res) => {
                if(res.data.success) {
                    this.setState({ loader: false, otp_send: true, timer: OTP_RESEND_TIMER }, () => {
                        this.startTimer();
                    })
                } else {
                    // If invalid access token then get new access token using same function
                    if(res.data.message === INVALID_TOKEN) {
                        await removeAllLocalCache();
                        this.loginWithOtp(e);
                    } else {
                        this.setState({ loader: false })
                    }
                }
            }
        )
    }
    resendOtp = () => {
        let data = {
            email_id: this.state.email,
            p_id: process.env.P_ID,
        }
        // Login API from Redux actions
        this.props.resendOtp(data).then(
            async (res) => {
                if(res.data.success) {
                    this.setState({ loader: false, otp_send: true, timer: OTP_RESEND_TIMER }, () => {
                        this.startTimer();
                    })
                } else {
                    // If invalid access token then get new access token using same function
                    if(res.data.message === INVALID_TOKEN) {
                        await removeAllLocalCache();
                        this.loginWithOtp(e);
                    } else {
                        this.setState({ loader: false })
                    }
                }
            }
        )
    }
    // Event handler for login button
    loginWithOtp = (e) => {
        e.preventDefault();
        this.sendOtp();
    }
    // Timer to Resend Otp
    startTimer = () => {
        this.timer = setInterval(() => {
            this.setState({ timer: this.state.timer - 1 })
        }, 1000);
    }
    // Event handler for login button
    verifyOtp = (e) => {
        e.preventDefault();
        let deviceToken = localStorage.getItem("device_token");
        if(deviceToken == null) {
            deviceToken = uuidv4();
        }
        let data = {
            p_id: process.env.P_ID,
            email_id: this.state.email,
            otp: this.state.password,
            os_type_id: OS_TYPES[osName],
            serial_number: "NA",
            model: browserName,
            version: fullBrowserVersion,
            unique_id: nanoid(),
            device_token: deviceToken
        }
        // Login API from Redux actions
        this.props.verifyOtp(data).then(
            async (res) => {
                if(res.data.success) {
                    if(this.props.loginFromPackage === true) {
                        this.props.router.push('order-summary')
                    } else {
                        if(this.props.router.query?.redirect_uri) {
                            this.props.router.push(this.props.router.query.redirect_uri);
                        } else {
                            this.props.router.push('/')
                        }
                    }
                } else {
                    // If invalid access token then get new access token using same function
                    // if(res.data.message === INVALID_TOKEN) {
                    //     removeLocalStorage();
                    //     this.loginWithOtp(e);
                    // } else {
                    // }
                    this.setState({ loader: false })
                }
            }
        )
    }
    // Event handler for login button
    loginOrRegister = async (e) => {
        e.preventDefault();
        const { loginFromPackage } = this.props;
        if(this.isValid()) {
            this.setState({ errors: {}, loader: true });
            // If access token is not present in local storage, call salt key API
            let refresh_token = await localStorage.getItem("refresh_token");
            let client_secret = await localStorage.getItem("client_secret");
            let token = await localStorage.getItem("token");
            let expired_at = await localStorage.getItem("expired_at");
            // If token is not saved in localStorage, then get it from server using Salt Key API
            if(refresh_token != null && client_secret != null && token != null) {
                if(!moment().isBefore(moment(expired_at))) {
                    await this.props.getRefreshToken({
                        client_secret: client_secret,
                        refresh_token: refresh_token
                    });
                    if(this.state.otp_login) {
                        if(this.state.otp_send) {
                            this.verifyOtp(e);
                        } else {
                            this.loginWithOtp(e);
                        }
                    } else {
                        this.login(e);
                    }
                } else {
                    if(this.state.otp_login) {
                        if(this.state.otp_send) {
                            this.verifyOtp(e);
                        } else {
                            this.loginWithOtp(e);
                        }
                    } else {
                        this.login(e);
                    }
                }
            } else {
                let deviceToken = uuidv4();
                await localStorage.setItem("device_token", deviceToken);
                await this.props.getSaltKey({
                    p_id: process.env.P_ID,
                    os_type_id: OS_TYPES[osName],
                    pkey: process.env.P_KEY,
                    serial_number: "NA",
                    model: browserName,
                    version: fullBrowserVersion,
                    unique_id: nanoid(),
                    device_token: deviceToken
                });
                if(this.state.otp_login) {
                    if(this.state.otp_send) {
                        this.verifyOtp(e);
                    } else {
                        this.loginWithOtp(e);
                    }
                } else {
                    this.login(e);
                }
            }
        }
    }
    // Event handler for Social Login
    socialLogin = (data) => {
        this.props.socialLogin(data).then(
            async (res) => {
                if(res.data.success) {
                    this.setState({ loader: false });
                    if(this.props.loginFromPackage === true) {
                        this.props.router.push('/packages')
                    } else {
                        if(this.props.router.query?.redirect_uri) {
                            this.props.router.push(this.props.router.query.redirect_uri);
                        } else {
                            this.props.router.push('/')
                        }
                    }
                } 
                // IF Invald access token, remove local storage and recall social login
                if(res.data.message === INVALID_TOKEN) {
                    await removeAllLocalCache();
                    this.socialLoginAccess(data)
                } else {
                    this.setState({ loader: false })
                }
            }
        )
    }
    socialLoginAccess = async (data, deviceToken) => {
        // If access token is not present in local storage, call salt key API
        let refresh_token = await localStorage.getItem("refresh_token");
        let client_secret = await localStorage.getItem("client_secret");
        let token = await localStorage.getItem("token");
        let expired_at = await localStorage.getItem("expired_at");
        // If token is not saved in localStorage, then get it from server using Salt Key API
        if(refresh_token != null && client_secret != null && token != null) {
            if(!moment().isBefore(moment(expired_at))) {
                await this.props.getRefreshToken({
                    client_secret: client_secret,
                    refresh_token: refresh_token
                });
                this.socialLogin(data);
            } else {
                this.socialLogin(data);
            }
        } else {
            let deviceToken = uuidv4();
            await localStorage.setItem("device_token", deviceToken);
            await this.props.getSaltKey({
                p_id: process.env.P_ID,
                os_type_id: OS_TYPES[osName],
                pkey: process.env.P_KEY,
                serial_number: "NA",
                model: browserName,
                version: fullBrowserVersion,
                unique_id: nanoid(),
                device_token: deviceToken
            });
            this.socialLogin(data);
        }
    }

    // Handle Google Login
    handleGoogleLogin = async (res) => {
        try {
            // const userData = await axios.get(`${PAGES.LOGIN.O_AUTH_URL+res.access_token}`);
            const { email, name } = res;
            let deviceToken = localStorage.getItem("device_token");
            if(deviceToken == null) {
                deviceToken = uuidv4();
            }
            let data = {
                p_id: process.env.P_ID,
                name: name,
                first_name: name.split(" ")[0] ? name.split(" ")[0] : "",
                last_name: name.split(" ")[1] ? name.split(" ")[1] : "",
                email: email,
                type: "GOOGLE",
                os_type_id: OS_TYPES[osName],
                serial_number: "NA",
                model: browserName,
                version: fullBrowserVersion,
                unique_id: nanoid(),
                device_token: deviceToken,
            }
            // If access token is not present in local storage, call salt key API
            let refresh_token = await localStorage.getItem("refresh_token");
            let client_secret = await localStorage.getItem("client_secret");
            let token = await localStorage.getItem("token");
            let expired_at = await localStorage.getItem("expired_at");
            // If token is not saved in localStorage, then get it from server using Salt Key API
            if(refresh_token != null && client_secret != null && token != null) {
                if(!moment().isBefore(moment(expired_at))) {
                    await this.props.getRefreshToken({
                        client_secret: client_secret,
                        refresh_token: refresh_token
                    });
                    this.socialLoginAccess(data, data.device_token);            
                } else {
                    this.socialLoginAccess(data, data.device_token);            
                }
            } else {
                let deviceToken = uuidv4();
                await localStorage.setItem("device_token", deviceToken);
                await this.props.getSaltKey({
                    p_id: process.env.P_ID,
                    os_type_id: OS_TYPES[osName],
                    pkey: process.env.P_KEY,
                    serial_number: "NA",
                    model: browserName,
                    version: fullBrowserVersion,
                    unique_id: nanoid(),
                    device_token: deviceToken
                });
                this.socialLoginAccess(data, data.device_token);            
            }
        } catch (e) {
            console.log(e)
        }        
    }

    // Handle Google Login
    handleAppleLogin = async (res) => {
        try {
            // const userData = await axios.get(`${PAGES.LOGIN.O_AUTH_URL+res.access_token}`);
            const { email, name } = res;
            let deviceToken = localStorage.getItem("device_token");
            if(deviceToken == null) {
                deviceToken = uuidv4();
            }
            let data = {
                p_id: process.env.P_ID,
                name: name,
                first_name: name.split(" ")[0] ? name.split(" ")[0] : "",
                last_name: name.split(" ")[1] ? name.split(" ")[1] : "",
                email: email,
                type: "APPLE",
                os_type_id: OS_TYPES[osName],
                serial_number: "NA",
                model: browserName,
                version: fullBrowserVersion,
                unique_id: nanoid(),
                device_token: deviceToken,
            }
            // If access token is not present in local storage, call salt key API
            let refresh_token = await localStorage.getItem("refresh_token");
            let client_secret = await localStorage.getItem("client_secret");
            let token = await localStorage.getItem("token");
            let expired_at = await localStorage.getItem("expired_at");
            // If token is not saved in localStorage, then get it from server using Salt Key API
            if(refresh_token != null && client_secret != null && token != null) {
                if(!moment().isBefore(moment(expired_at))) {
                    await this.props.getRefreshToken({
                        client_secret: client_secret,
                        refresh_token: refresh_token
                    });
                    this.socialLoginAccess(data, data.device_token);            
                } else {
                    this.socialLoginAccess(data, data.device_token);            
                }
            } else {
                let deviceToken = uuidv4();
                await localStorage.setItem("device_token", deviceToken);
                await this.props.getSaltKey({
                    p_id: process.env.P_ID,
                    os_type_id: OS_TYPES[osName],
                    pkey: process.env.P_KEY,
                    serial_number: "NA",
                    model: browserName,
                    version: fullBrowserVersion,
                    unique_id: nanoid(),
                    device_token: deviceToken
                });
                this.socialLoginAccess(data, data.device_token);            
            }
        } catch (e) {
            console.log(e)
        }        
    }

    getFBUserInfo = () => {
        window.FB.api('/me', { fields: 'id, name, email' }, fbUser => {
            this.handleFacebookLogin({
                name: fbUser.name,
                email: fbUser.email
            });
        })
    }

    facebookLogin = () => {
        // window.FB.getLoginStatus((status) => {
        //     console.log(status, "Status");
        // //     if(!status.authResponse) {
        // //         window.FB.login();
        // //     } else if(status.status === "connected") {
        // //         this.getFBUserInfo();
        // //     }
        // })
        FB.login((response) => {
            if (response.authResponse) {
                //  console.log('Welcome!  Fetching your information.... ');
                 this.getFBUserInfo();
            } else { 
                //  <!-- If you are not logged in, the login dialog will open for you to login asking for permission to get your public profile and email -->
                // console.log('User cancelled login or did not fully authorize.'); 
            }
        });
    }

    // googleLogin = async () => {
        // const auth2 = window.gapi.auth2.getAuthInstance();
        // try {
        //     const user = await auth2.signIn();
        //     const profile = user.getBasicProfile();
        //     this.handleGoogleLogin({ name: profile.getName(), email: profile.getEmail() });
        // // Add your logic for handling the signed-in user
        // } catch (error) {
        //     console.error('Error signing in:', error);
        // }
        // let response = await this.enableGoogleLogin();
        // if(response) {

        // }
    // }

    // Event handler for Social Login - facebook
    handleFacebookLogin = async (res) => {
        const { name, email } = res;
        let deviceToken = localStorage.getItem("device_token");
        if(deviceToken == null) {
            deviceToken = uuidv4();
        }
        let data = {
            p_id: process.env.P_ID,
            name: name,
            first_name: name.split(" ")[0] ? name.split(" ")[0] : "",
            last_name: name.split(" ")[1] ? name.split(" ")[1] : "",
            email: email,
            type: "FB",
            os_type_id: OS_TYPES[osName],
            serial_number: "NA",
            model: browserName,
            version: fullBrowserVersion,
            unique_id: nanoid(),
            device_token: deviceToken
        }
        // If access token is not present in local storage, call salt key API
        let refresh_token = await localStorage.getItem("refresh_token");
        let client_secret = await localStorage.getItem("client_secret");
        let token = await localStorage.getItem("token");
        let expired_at = await localStorage.getItem("expired_at");
        // If token is not saved in localStorage, then get it from server using Salt Key API
        if(refresh_token != null && client_secret != null && token != null) {
            if(!moment().isBefore(moment(expired_at))) {
                await this.props.getRefreshToken({
                    client_secret: client_secret,
                    refresh_token: refresh_token
                });
                this.socialLogin(data, data.device_token);            
            } else {
                this.socialLogin(data, data.device_token);            
            }
        } else {
            let deviceToken = uuidv4();
            await localStorage.setItem("device_token", deviceToken);
            await this.props.getSaltKey({
                p_id: process.env.P_ID,
                os_type_id: OS_TYPES[osName],
                pkey: process.env.P_KEY,
                serial_number: "",
                model: browserName,
                version: fullBrowserVersion,
                unique_id: nanoid(),
                device_token: deviceToken
            });
            this.socialLogin(data, data.device_token);            
        }
    }

    // Initialize Google Login
    initializeGoogleSigIn = () =>  {
        window.gapi.load('auth2', () => {
            gapi.auth2.init({
                client_id: PAGES.LOGIN.G_CLIENT_ID,
                // response_type: "code"
            })
            // window.gapi.load('signin2', function () {
            //     // render a sign in button
            //     // using this method will show Signed In if the user is already signed in
            //     var params = {
            //         'width': 240,
            //         'height': 50,
            //         'longtitle': true,
            //         onsuccess: (googleUser) => {
            //             console.log('user has finished sigin', googleUser);
            //         }
            //     }
            //     window.gapi.signin2.render('loginButton', params)
            // })
        })
    }

    // Google siginin script
    // insertScript = () => {
    //     const script = document.createElement('script');
    //     script.src = 'https://apis.google.com/js/platform.js';
    //     script.onload = ()=> {
    //        this.initializeGoogleSigIn();
    //     }
    //     document.body.appendChild(script);
    // }
    componentDidMount() {
        // Initialize script when mounting component
        // this.insertScript();
        clearInterval(this.timer);
    }
    componentDidUpdate(prevState) {
        if(prevState.timer !== this.state.timer) {
            if(this.state.timer < 1) {
                clearInterval(this.timer);
            }
        }
    }
    render() {
        const { email, password, show_password, errors, loader, otp_login, otp_send, timer, otp } = this.state;
        return (
            <div className="bg-all-con h-100">
                <div className="bg-all h-100">
                    <div className="container-fluid h-100 px-lg-5 px-3 position-relative">
                        <div className="row h-100 justify-content-center align-items-center login-component px-xxl-5 px-xl-4 px-lg-3 px-3 py-5">
                            {/* <div className='col-lg-5 col-md-9 col-sm-11 col-12 ps-xl-5 ps-lg-4 px-0 mb-4 mb-lg-0'>
                                <Link href="/" legacyBehavior>
                                    <a className="navbar-brand w-100 h-100 d-flex align-items-center mt-2">
                                        <img 
                                            src={PAGES.LOGIN.LOGO_URL_50}
                                            width={60}
                                            height={60}
                                            className="tenent-logo"
                                            alt={`${CONFIG.APP_NAME}-logo`}
                                        />
                                        <span className="logo">
                                            <img 
                                                src={PAGES.LOGIN.LOGO_TEXT_URL}
                                                height="100%"
                                                alt={`${CONFIG.APP_NAME}-logo`}
                                            />
                                        </span>
                                    </a>
                                </Link>
                                <h1 className='mt-4 mb-5 text-static-title ps-1'>Unlock Live entertainment for the entire family</h1> */}
                                {/* <div className='d-flex flex-row stats-card'>
                                    <div className='mx-2 text-center stat-card'>
                                        <div className='card c-card'>
                                            <div className="card-body px-3 py-2">
                                                <h2 className='display-4'>75+</h2>
                                            </div>
                                        </div>
                                        <p className="mt-2">FREE CHANNELS</p>
                                    </div>
                                    <div className='mx-2 text-center stat-card'>
                                        <div className='card c-card'>
                                            <div className="card-body px-3 py-2">
                                                <h2 className='display-4'>05+</h2>
                                            </div>
                                        </div>
                                        <p className="mt-2">LANGUAGES</p>
                                    </div>
                                    <div className='mx-2 text-center stat-card'>
                                        <div className='card c-card'>
                                            <div className="card-body px-3 py-2">
                                                <h2 className='display-4'>24x7</h2>
                                            </div>
                                        </div>
                                        <p className="mt-2">NON-STOP</p>
                                    </div>
                                </div> */}
                            {/* </div> */}
                            <div className='col-lg-7 col-12'>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-7 col-xl-8 col-lg-9 col-md-9 col-sm-11 col-12 px-0">
                                        <div className="card c-card  p-md-3 p-0 position-relative">
                                            <div className="card-body pb-0">
                                                <h3 className="mb-3 text-light">Login</h3>
                                                <div className='mt-4 d-flex flex-row'>
                                                    <div className="d-flex flex-row w-100 py-3 px-5 social_btn google-btn mb-3 justify-content-center rounded align-items-center">
                                                        <GoogleOAuthProvider clientId={PAGES.LOGIN.G_CLIENT_ID}>
                                                            <GoogleSignin
                                                                handleGoogleLogin={this.handleGoogleLogin}
                                                            />
                                                        </GoogleOAuthProvider>
                                                    </div>
                                                    <div className="d-flex flex-row w-100 py-3 px-5 social_btn mb-3 facebook-btn justify-content-center rounded align-items-center mx-3">
                                                        <span onClick={this.facebookLogin} className="text-center w-100 cursor-pointer">
                                                            <img src="/images/social/facebook.png" width="25" height="25" className="m-auto" alt={CONFIG.APP_NAME+"-facebook-icon"}/>
                                                            {/* <span className="ms-3 google ff-ember-rg">Continue with Facebook</span> */}
                                                        </span>
                                                    </div>
                                                    <div className="d-flex flex-row w-100 py-3 px-5 social_btn mb-3 apple-btn justify-content-center rounded align-items-center">
                                                        <AppleSignIn handleAppleSignIn={this.handleAppleLogin}/>
                                                    </div>
                                                </div>
                                                <div className='or-text position-relative'>
                                                    <hr/>
                                                    <span className='text-light'>or</span>
                                                </div>
                                                <form onSubmit={this.loginOrRegister} autoComplete="false">
                                                    <div className="form-group auth-disclaimer mb-2">
                                                        <label className="form-label">Registered Email</label>
                                                        <input 
                                                            className={classNames("form-control", { 'is-invalid': errors.email })}
                                                            type="email"
                                                            name="email"
                                                            value={email}
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.email && <span className="invalid-feedback">{errors.email}</span>}
                                                    </div>
                                                    <div className={classNames("form-group mb-lg-3 mb-2", {"d-none": otp_login && !otp_send})}>
                                                        <label className="form-label">{otp_login ? "OTP" : "Password"}</label>
                                                        <div className="password position-relative">
                                                            <input 
                                                                className={classNames("form-control", { 'is-invalid': errors.password })}
                                                                type={show_password ? "text" : "password"}
                                                                name="password"
                                                                max={otp_login ? 6 : 20}
                                                                value={password}
                                                                onChange={this.handleChange}
                                                            />
                                                            <img src={show_password ? '/images/show.png' : '/images/hide.png'} onClick={() => this.setState({ show_password: !show_password })} className="position-absolute" width="25" style={{ right: errors.password ? 40 : 15, top: errors.password ? "30%" : "50%", transform: "translateY(-50%)", cursor: "pointer" }}/>
                                                            {errors.password && <span className="invalid-feedback">{errors.password}</span>}
                                                        </div>
                                                        {(otp_login && otp_send) && 
                                                            <div className="d-flex flex-row justify-content-end align-items-center mt-2">
                                                                {timer !== 0 && <p className="ff-ember-rg text-light mb-0"><small>{timer} secs left to</small></p>}
                                                                <button type="button" className="btn btn-sm btn-light ff-ember-rg text-end ms-2" style={{ disabled: timer > 0 ? 1 : 0.5 }} onClick={this.resendOtp} disabled={timer > 0}>Resend Otp</button>
                                                            </div>
                                                        }
                                                        {/* Forgot password link to another page */}
                                                        {/* <div className="my-3 text-end"><Link href="/forgot-password"><a className="text-primary" style={{ cursor: "pointer"}}>Forgot Password?</a></Link></div> */}
                                                    </div>
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                        <label className="form-check-label text-light" htmlFor="flexCheckDefault">
                                                            Remember this device
                                                        </label>
                                                    </div> */}
                                                    <button type="submit" className="w-100 btn btn-primary py-2 my-3">{otp_login ? otp_send ? "Verify Otp" : "Send Otp" : "Continue"}</button>
                                                   
                                                    <p className='auth-disclaimer mb-0 text-light'><small>By using {CONFIG.APP_NAME} website, you agree to our <a href="/terms-and-conditions" className='fw-bold text-primary' target='_blank'>Terms and Conditions</a></small></p>
                                                </form>
                                            </div>
                                            {loader && <Loader/>}
                                        </div>
                                        <div className="card c-card position-relative mt-2">
                                            <div className="card-body p-lg-4 p-md-3 p-2">
                                                <p className='fw-bold text-secondary mb-md-3 mb-1'>More account related options</p>
                                                {/* <h6 className="cursor-pointer" onClick={() => this.setState({ otp_login: !otp_login })}>Login with {otp_login ? "Passowrd" : "OTP"}</h6> */}
                                                <h6>
                                                    <Link href="/forgot-password" className='text-light' onClick={(e) => e.target?.classList.add("disabled")}>
                                                        Forgot Password
                                                    </Link>
                                                </h6>
                                                <h6>
                                                    <Link href="/sign-up" className='text-light' onClick={(e) => e.target?.classList.add("disabled")}>
                                                        Create an Account with {CONFIG.APP_NAME}
                                                    </Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AuthPageLogo/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginOrRegister: (data, isLogin) => dispatch(loginOrRegister(data,isLogin)),
        login: (data) => dispatch(login(data)),
        socialLogin: (data) => dispatch(socialLogin(data)),
        addAlertMessage: (message) => dispatch(addAlertMessage(message)),
        getSaltKey: (data) => dispatch(getSaltKey(data)),
        getRefreshToken: (data) => dispatch(getRefreshToken(data)),
        sendOtp: (data) => dispatch(sendOtp(data)),
        resendOtp: (data) => dispatch(resendOtp(data)),
        verifyOtp: (data) => dispatch(verifyOtp(data))
    }
}

const mapStateToProps = (state) => {
    return {     
        loginFromPackage: state.AppReducer.loginFromPackage     
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginComponent));