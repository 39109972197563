import React, { useEffect } from 'react';
// import AppleLogin from 'react-apple-login'
import CONFIG from '../config';
import jwtDecode from 'jwt-decode';

const AppleSignIn = ({ handleAppleSignIn }) => {
    useEffect(() => {
        // Initialize the Apple Sign In button
        window.AppleID.auth.init({
            clientId: 'com.channelwall.web',
            // clientId: 'eyJhbGciOiJFUzI1NiIsImtpZCI6IjZBQ1JKU1M5QTYifQ.eyJpc3MiOiIyWjI1RjNYNVNGIiwiYXVkIjoiaHR0cHM6Ly9hcHBsZWlkLmFwcGxlLmNvbSIsInN1YiI6ImNvbS5jaGFubmVsd2FsbC50diIsImlhdCI6MTY5Nzc0MzM5MSwiZXhwIjoxNzEzNTU0NTkxfQ.AY9n90seEMXX0RTPPb6VXE_wN1hWlWDN6PbdE5lWJZyVGoUS70PCXMb7Z3itEaVQKXT74e7OoVa7hUQdSRnCEw',
            scope: 'email name', // Adjust the scope based on your requirements
            redirectURI: 'https://www.channelwall.com/login',
            usePopup: true
        }, (res) => {
            console.log(res)
        });

        // window.AppleID.auth?.handleAuthStateChange((authState) => {
        //     console.log('Auth State Change:', authState);
        // });
        // // Optionally handle the authorization state changes
    }, []);

    const handleSignIn = () => {
        try {
            // Trigger Apple Sign In
            window.AppleID.auth.signIn().then((res) => {
                if(res?.authorization?.id_token)  {
                    let decodedToken = jwtDecode(res.authorization.id_token);
                    handleAppleSignIn({ email: decodedToken.email, name: decodedToken.email.split("@")[0] });
                }
            }).catch(err => console.log(err));
        } catch (error) {
            console.log("Apple Sign in Err", error)
        }
    };

    return (
        <span onClick={handleSignIn} className="text-center w-100 cursor-pointer">
            <img src="/images/social/apple.png" width="25" height="25" alt={CONFIG.APP_NAME+"-apple-icon"}/>
            {/* <span className="ms-3 text-light ff-ember-rg">Connect with Apple</span> */}
        </span>
    );
    // return (
    //     <AppleLogin 
    //         clientId="com.channelwall.web" 
    //         scope='email name'
    //         // render={() => (
    //         //     <span className="text-center w-100 cursor-pointer">
    //         //         <img src="/images/social/google.png" width="25" height="25" alt={CONFIG.APP_NAME+"-google-icon"}/>
    //         //         <span className="ms-3 apple ff-ember-rg">Connect with Apple</span>
    //         //     </span>
    //         // )}
    //         callback={(res) => console.log(res, "Res")}
    //         // redirectURI="http://localhost:3002/login" 
    //         redirectURI="https://www.channelwall.com/login" 
    //         usePopup={true}
    //     />
    // )
};

export default AppleSignIn;
